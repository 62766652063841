import React from "react";

const CategoryItem: React.FC<{
  image: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  title: string;
  className?: string;
}> = (props) => {
  return (
    <div className="">
      <div className="dm__mb-[-50px] dm__flex dm__justify-center">
        <img
          src={props.image}
          className=" md:dm__h-[100px] dm__h-[60px] dm__drop-shadow-md"
          alt={props.title}
        />
      </div>
      <div
        className={`dm__h-36 dm__rounded-3xl ${props.className} dm__text-center dm__pt-20 dm__px-4 md:dm__px-8 dm__text-sm md:dm__text-base`}
      >
        {props.title}
      </div>
    </div>
  );
};

const Category: React.FC = () => {
  return (
    <div>
      <p className=" dm__text-center dm__font-bold dm__text-2xl md:dm__text-4xl dm__py-16">
        Categories
      </p>
      <div className=" dm__grid dm__grid-cols-3 dm__px-4 md:dm__grid-cols-5 md:dm__gap-24 dm__gap-4">
        <CategoryItem
          title="All Categories"
          className="categories__gradient_white"
          image={require("../../assets/all.png")}
        />
        <CategoryItem
          title="Custom Embed Solution"
          className="categories__gradient_red"
          image={require("../../assets/custom_embed_solution.png")}
        />

        <CategoryItem
          title="Dailymotion Video Tiles"
          className="categories__gradient_yellow"
          image={require("../../assets/video_tiles.png")}
        />
        <CategoryItem
          title="Dynamic Previews"
          className="categories__gradient_white"
          image={require("../../assets/dynamic_preview.png")}
        />
        <CategoryItem
          title="Recommending Playlist"
          className="categories__gradient_white"
          image={require("../../assets/recommending_previews.png")}
        />
      </div>
    </div>
  );
};

export default Category;
