import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Header/logo.png";
import { buttonClass } from "../utils";

const Footer: React.FC = () => {
  return (
    <div className=" dm__bg-[#EEBEFA]/10 dm__py-12 dm__font-retina">
      <div className=" dm__max-w-7xl dm__mx-auto dm__font-retina">
        <div className=" dm__flex flex-row dm__w-full dm__items-center">
          <div className=" dm__hidden md:dm__flex dm__pr-[118px]">
            <img
              src={Logo}
              className="dm__h-16"
              alt="Dailymotion Professsional Service"
            />
          </div>
          <div className=" dm__flex-1 dm__px-4 md:dm__px-0">
            <div className=" dm__relative dm__w-full ">
              <input
                type="text"
                className=" dm__w-full dm__rounded-[33px] dm__bg-white dm__p-6 dm__placeholder-[#4F596A] "
                placeholder="Stay in the loop  - sign up with your email"
              />
              <button
                type="submit"
                className={buttonClass(
                  " !dm__py-3  !dm__px-6 dm__w-full md:dm__w-48 md:dm__absolute dm__mt-4 md:dm__mt-0 dm__right-2 dm__top-[10px]"
                )}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="dm__max-w-xl  dm__py-12 dm__mx-auto dm__grid dm__grid-cols-2 dm__px-4 md:dm__px-0">
        <div>
          <p className=" dm__text-primary dm__text-lg dm__font-medium">
            Help Menu
          </p>
          <ul className=" footer-menu">
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/">Features</Link>
            </li>
            <li>
              <Link to="/">Contact</Link>
            </li>
            <li>
              <Link to="/">Help & Support</Link>
            </li>
          </ul>
        </div>
        <div>
          <p className=" dm__text-primary dm__text-lg dm__font-medium">
            Services
          </p>
          <ul className=" footer-menu">
            <li>
              <Link to="/">Custom Embed Solution</Link>
            </li>
            <li>
              <Link to="/">Dynamic Previews</Link>
            </li>
            <li>
              <Link to="/">Dailymotion Video Titles</Link>
            </li>
            <li>
              <Link to="/">Recommending Playlist</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
