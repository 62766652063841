import React from "react";
import { Link } from "react-router-dom";
const FeatureData = [
  {
    title: "Article Previews",
    category: "Dynamic Previews",
    description:
      "Showcase important articles and stories from your newsrooms and redirect your readers to a destination of your choice.",
    image: require("routes/Home/assets/features/feature-0.png"),
  },
  {
    title: "Chrome Heavy Ads Intervention Detection",
    category: "Custom Embed Solution",
    description: "Identify and unload online ads that are deemed as ‘heavy’.",
    image: require("routes/Home/assets/features/feature-1.png"),
  },
  {
    title: "Collections",
    category: "Recommending Playlist",
    description:
      "Show related videos along with basic metadata after the playback is finished.",
    image: require("routes/Home/assets/features/feature-2.png"),
  },
  {
    title: "Contextual Embed",
    category: "Custom Embed Solutions",
    description:
      "Automatically embed relevant videos to maximise articles’ audience and inventory.",
    image: require("routes/Home/assets/features/feature-3.png"),
  },
  {
    title: "Enriched Embed",
    category: "Custom Embed Solutions",
    description:
      "Display important metadata in the unfolded box outside of the video player to better engage audience.",
    image: require("routes/Home/assets/features/feature-4.png"),
  },
  {
    title: "Google AMP",
    category: "Custom Embed Solutions",
    description:
      "Provide an adaptation of the custom embed framework over AMP, with extra intuitive features.",
    image: require("routes/Home/assets/features/feature-5.png"),
  },
  {
    title: "Hidden/Closed Player",
    category: "Custom Embed Solutions",
    description: "Give extra option to readers for specific use cases.",
    image: require("routes/Home/assets/features/feature-6.png"),
  },
  {
    title: "Infinite Scrolling",
    category: "Custom Embed Solutions",
    description: "Load content continuously as the audience scrolls down.",
    image: require("routes/Home/assets/features/feature-7.png"),
  },
  {
    title: "In-video Call to Action",
    category: "Custom Embed Solutions",
    description:
      "Use a customizable configuration within videos to display CTA to redirect readers.",
    image: require("routes/Home/assets/features/feature-8.png"),
  },
  {
    title: "Lazy Loaded Player",
    category: "Custom Embed Solutions",
    description:
      "Only load the custom embed script upon the reader actively scrolling the web page.",
    image: require("routes/Home/assets/features/feature-9.png"),
  },
  {
    title: "Livestream Embed",
    category: "Custom Embed Solutions",
    description:
      "Swap live feeds automatically with the live feed record when your live is off-air and vice versa.",
    image: require("routes/Home/assets/features/feature-10.png"),
  },
  {
    title: "Outstream Player",
    category: "Custom Embed Solutions",
    description:
      "Only load the custom embed script upon receiving an ad event.",
    image: require("routes/Home/assets/features/feature-11.png"),
  },
  {
    title: "Stories",
    category: "Dynamic Previews",
    description:
      "Display snackable videos in a familiar story format on your homepage, articles, or video sections.",
    image: require("routes/Home/assets/features/feature-12.png"),
  },
  {
    title: "Video Titles",
    category: "Dynamic Previews",
    description:
      "Create a custom section title above the video player embed and decide if you want to show video title or not.",
    image: require("routes/Home/assets/features/feature-13.png"),
  },
];

const Fature: React.FC = () => {
  return (
    <>
      {FeatureData.map((feature, index) => (
        <Link
          key={index}
          to="/"
          title={feature.title}
          className=" dm__rounded-3xl dm__overflow-hidden dm__flex dm__flex-col "
        >
          <div className="w-full md:dm__h-[170px] dm__text-center dm__overflow-hidden dm__bg-[#EEBEFA]/30">
            <img
              src={feature.image}
              className="  dm__max-h-full dm__inline-block"
              alt={feature.title}
            />
          </div>
          <div className=" dm__p-4 dm__flex dm__gap-2 dm__flex-1 dm__flex-col dm__bg-[#1E293B]">
            <p className=" dm__font-bold md:dm__text-2xl dm__text-white">
              {feature.title}
            </p>
            <div>
              <div className="  dm__text-sm md:dm__text-base dm__text-white dm__py-1 dm__bg-[#334154] dm__inline-block dm__rounded-lg  dm__px-3">
                <p>{feature.category}</p>
              </div>
            </div>
            <p className=" dm__text-sm md:dm__text-base dm__text-[#94A3B8] dm__line-clamp-3">
              {feature.description}
            </p>
          </div>
        </Link>
      ))}
    </>
  );
};

export default Fature;
