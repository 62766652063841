import React from "react";
import Footer from "../Footer";
import Header from "../Header";

const Layout: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  return (
    <>
      <div className=" dm__max-w-[1336px] dm__mx-auto dm__font-retina">
        <Header />
        <div>{props.children}</div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
