import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

const Header: React.FC = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="  dm__flex dm__py-7 dm__flex-col dm__items-center md:dm__gap-0 lg:dm__flex-row dm__justify-between">
      <div className=" dm__w-full  dm__flex dm__items-center dm__justify-between dm__p-4 md:dm__p-0 md:dm__w-3/12">
        <Link to="/">
          <img
            src={Logo}
            className="md:dm__h-16 dm__h-12"
            alt="Dailymotion Professsional Service"
          />
        </Link>
        <div className=" dm__inline-block md:dm__hidden">
          <svg
            onClick={() => setToggleMenu(!toggleMenu)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className=" dm__w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
      </div>
      <div
        className={`dm__w-10/1 dm__justify-center dm__items-center dm__flex-col dm__p-4 md:dm__p-0 md:dm__flex-row dm__mt-10 lg:dm__mt-0 dm__gap-10 ${
          toggleMenu ? "dm__flex" : " dm__hidden"
        } md:!dm__flex `}
      >
        <Link
          to="/"
          className=" dm__text-primary dm__font-medium text-base  md:dm__text-lg"
        >
          Home
        </Link>
        <Link
          to="/"
          className=" dm__text-primary dm__font-medium text-base  md:dm__text-lg"
        >
          Features
        </Link>
        <Link
          to="/"
          className=" dm__text-primary dm__font-medium text-base  md:dm__text-lg"
        >
          Documentation
        </Link>
        <Link
          to="/"
          className=" dm__text-primary dm__font-medium text-base  md:dm__text-lg"
        >
          Contact
        </Link>
        <Link
          to="/"
          className=" dm__text-primary dm__font-medium text-base  md:dm__text-lg"
        >
          About Us
        </Link>
      </div>
      <div className=" dm__w-3/12"></div>
    </div>
  );
};

export default Header;
