import React from "react";
import Layout from "../../../components/Layout";

enum FontType {
  regular = "dm__font-retina",
  medium = "dm__font-medium",
  bold = "dm__font-bold",
  black = "dm__font-black",
}

const FontClass: any = {
  57: "dm__text-6xl",
  46: "dm__text-5xl",
  37: "dm__text-4xl",
  29: "dm__text-3xl",
  23: "dm__text-2xl",
  19: "dm__text-lg",
  15: "dm__text-base",
  12: "dm__text-sm",
  10: "dm__text-xs",
};

const FontListsItem: React.FC<{
  value: number;
  title: string;
  fontType: FontType;
}> = (props) => {
  return (
    <div className=" dm__flex flex-row dm__items-center dm__gap-4">
      <div className=" dm__text-right dm__font-light dm__text-sm dm__w-32">
        <p>{props.value}px</p>
        <p>{props.value * 0.625}rem</p>
      </div>
      <p className={`dm__font-retina ${FontClass[props.value]} `}>
        {props.title}
      </p>
    </div>
  );
};

const FontLists: React.FC<{
  title: string;
  fontType: FontType;
}> = (props) => {
  return (
    <div className=" dm__flex dm__flex-col dm__gap-7">
      <FontListsItem fontType={props.fontType} value={57} title={props.title} />
      <FontListsItem fontType={props.fontType} value={46} title={props.title} />
      <FontListsItem fontType={props.fontType} value={37} title={props.title} />
      <FontListsItem fontType={props.fontType} value={29} title={props.title} />
      <FontListsItem fontType={props.fontType} value={23} title={props.title} />
      <FontListsItem fontType={props.fontType} value={19} title={props.title} />
      <FontListsItem fontType={props.fontType} value={15} title={props.title} />
      <FontListsItem fontType={props.fontType} value={12} title={props.title} />
      <FontListsItem fontType={props.fontType} value={10} title={props.title} />
    </div>
  );
};

const GuideFonts = () => {
  return (
    <Layout>
      <div className=" dm__flex dm__gap-12 dm__flex-col dm__my-10">
        <FontLists fontType={FontType.regular} title="Retina Regular" />
        <FontLists fontType={FontType.medium} title="Retina Medium" />
        <FontLists fontType={FontType.bold} title="Retina Bold" />
        <FontLists fontType={FontType.black} title="Retina Black" />
      </div>
    </Layout>
  );
};

export default GuideFonts;
