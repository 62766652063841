import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Category from "./components/Category";
import Head from "./components/Head";
import Feature from "./components/Feature";
import { buttonClass } from "../../components/utils";

const Home: React.FC = () => {
  return (
    <Layout>
      <Head />
      <Category />
      <div>
        <div className=" dm__text-center dm__px-4 md:dm__px-0  dm__py-16">
          <p className="dm__font-bold dm__text-2xl md:dm__text-4xl">
            Simple and actionable scripts
          </p>
          <p className=" dm__text-xl md:dm__text-2xl dm__font-normal dm__mt-5 dm__text-[#616161] dm__px-10 md:dm__px-72">
            This Playground page is dedicated to showcasing some of our player's
            custom features and how they look in a live environment. Let's
            experience the demos!
          </p>
        </div>
        <div className=" dm__grid dm__grid-cols-2 dm__px-4 md:dm__px-0 md:dm__grid-cols-3 dm__gap-4 md:dm__gap-12">
          <Feature />
        </div>
      </div>
      <div className=" dm__text-center dm__max-w-2xl dm__mx-auto dm__py-16">
        {/* <div className="dm__bg-gradient-to-r dm__from-[#FFD7A9]/20 dm__to-[#FFAE70]/20 dm__text-center dm__px-16 dm__py-2 dm__rounded-[33px] dm__mx-4 md:dm__mx-28">
          <p className=" dm__text-sm md:dm__text-base dm__font-medium dm__text-[#CD10FF]">
            THE HOME FOR VIDEO THAT MATTER
          </p>
        </div> */}
        <p className="dm__text-2xl md:dm__text-4xl dm__py-14 dm__font-bold dm__text-primary dm__px-4 md:dm__px-20">
          Are you ready to grow your business with us?
        </p>
        <Link to="/" className={buttonClass()}>
          Get started
        </Link>
      </div>
    </Layout>
  );
};

export default Home;
