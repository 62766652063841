import { Route, Routes } from "react-router-dom";
import GuideFonts from "./routes/Guide/Fonts";
import Home from "./routes/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/guides/fonts" element={<GuideFonts />} />
    </Routes>
  );
}

export default App;
