import React from "react";

const Head: React.FC = () => {
  return (
    <div className="dm__relative  dm__px-4 md:dm__px-10">
      <div className=" dm__bg-[#FAECFE] dm__px-8 dm__py-8 md:dm__py-28 md:dm__px-20 dm__rounded-2xl md:dm__w-10/12 md:dm__mt-16">
        <div className=" dm__max-w-2xl">
          <p className=" dm__text-primary dm__text-4xl md:dm__text-5xl dm__font-black">
            Dailymotion Professional Services Playground
          </p>
          <p className=" dm__text-2xl md:dm__max-w-2xl md:dm__text-3xl dm__text-general dm__mt-2 md:dm__mt-7">
            Browse through our demonstration below to find{" "}
            <span className=" dm__text-grape dm__font-bold">code examples</span>{" "}
            and{" "}
            <span className=" dm__text-grape dm__font-bold">
              interactive test players
            </span>
          </p>
        </div>
      </div>
      <div className="dm__bg-primary md:dm__absolute md:dm__right-10 lg:dm__right-0 dm__top-[9%] dm__rounded-2xl dm__shadow-3xl md:dm__w-[300px] lg:dm__w-[500px] dm__h-[200px] lg:dm__h-[352px] dm__flex dm__items-center dm__justify-center dm__mt-4 md:dm__mt-0">
        <div className=" dm__bg-white dm__w-16 dm__h-16 dm__items-center dm__justify-center dm__flex dm__rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className=" dm__w-6 dm__h-6"
          >
            <path
              fillRule="evenodd"
              d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Head;
